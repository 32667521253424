import axios from "axios";

// development
// export const BASE_URL = "http://localhost:8000/";
// export const BASE_URL = "https://novalyabackend.novalya.com/";
export const BASE_URL = "https://stagingbackend.novalya.com/";
export const API_BASE_URL = BASE_URL + "user/api";
export const EXTENSION_BASE_URL = BASE_URL + "extension/api";
export const ADMIN_BASE_URL = BASE_URL + "admin/api";

//live
// const API_BASE_URL = 'https://novalyabackend.threearrowstech.com/user/api';
//axios.defaults.headers.common['authorization'] = 'Bearer '+localStorage.getItem('token')

function updateAuthorizationHeader() {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["authorization"] = "Bearer " + token;
}

axios.interceptors.response.use(
  response => {
    // If the response is successful, just return the response
    return response;
  },
  error => {
    // If the error response status is 401, handle logout

    if (error.response && error.response.status === 401 ) {
       const TokenCheck = localStorage.getItem('token') || localStorage.getItem('auth-token');
      localStorage.removeItem("auth-token");
      localStorage.removeItem("token");
      if(TokenCheck){
      window.location.href = "/login"

      }
    }
    return Promise.reject(error); // Otherwise, return the error to be handled
  }
);

export function registerUser(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/register", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getCompanyLogo11(params, callback, errorCallback) {
  axios
    .post(BASE_URL + "admin/api/get-company-logo", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getCompanyLogo(params) {
  return axios
    .post(BASE_URL + "admin/api/get-company-logo", params)
    .then((response) => {
      return response; // Adjust based on your response structure
    })
    .catch((error) => {
      console.error("Error fetching company logo:", error);
      throw error; // Re-throw the error so it can be handled with async/await
    });
}

export function unlinkFacebookAccount(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .delete(BASE_URL + "api/facebook/delete", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function unlinkInstagramAccount(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .delete(BASE_URL + "api/instagram/delete", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updatelanguage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/updatelanguage", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function setLoginTokenByAdmin(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"loginfromadminsettoken"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deploy_user(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/deployuser", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function authenticate(service, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL, service)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function eventRegistration(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/eventregistration", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function dashboarddataApi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"dashboarddata"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getreferralUsers(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"refferedUsers"}`, '')
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function affiliateCustomers(params,callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"affiliate-customers"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getnews(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"news"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function authUserData(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"userdata"}`, "")
    .then((response) => {
      const Email = response?.data?.data?.email;
      const isAdmin = sessionStorage.getItem("iSAdmin");
      if (Email && isAdmin !== "true") {
        brevoLogs(Email);
      }
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getTicketCount(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"ticketCount"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function germanEventCharge(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"charge"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateUserLimits(params, callback, errorCallback) {
  axios
    .post(ADMIN_BASE_URL + "/updateConnectionlimits", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

const brevoLogs = (Email) => {
  // Clear previous sib and sendinblue objects to avoid caching issues
  if (window.sib) {
    delete window.sib;
  }
  if (window.sendinblue) {
    delete window.sendinblue;
  }

  (function () {
    window.sib = {
      equeue: [],
      client_key: "i4qt1wzf0zwt2i755w6vkpru",
    };
    /* Set the email dynamically */
    window.sib.email_id = Email;

    window.sendinblue = {};
    for (
      let j = ["track", "identify", "trackLink", "page"], i = 0;
      i < j.length;
      i++
    ) {
      (function (k) {
        window.sendinblue[k] = function () {
          const arg = Array.prototype.slice.call(arguments);
          (
            window.sib[k] ||
            function () {
              const t = {};
              t[k] = arg;
              window.sib.equeue.push(t);
            }
          )(arg[0], arg[1], arg[2], arg[3]);
        };
      })(j[i]);
    }

    const n = document.createElement("script"),
      i = document.getElementsByTagName("script")[0];
    n.type = "text/javascript";
    n.id = "sendinblue-js";
    n.async = true;
    // Append a timestamp to the script URL to prevent caching
    n.src = `https://sibautomation.com/sa.js?key=${window.sib.client_key
      }&t=${new Date().getTime()}`;
    i.parentNode.insertBefore(n, i);
    // Initialize page tracking
    window.sendinblue.page();
  })();

  window.sendinblue.track("page", {
    email: Email,
  });
};

export function get_user_data_by_referral_id(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/singleuserdata", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getPlans(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getsubscriptionitems", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getPlansNew(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getplansbyfamilyid", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getupgradePlans(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getupgradesubscriptionitems", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getSinglePlans(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getsingleitem", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getCustomerPortal(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/createportalsession", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function validate_coupon(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/checkcoupon", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function affiliate(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/createaffiliateuser", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update payout information
export function UpdatePayoutDetailsApi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/updatepayoutdetails", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update affiliate code
export function updateaffiliatecode(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/updateaffiliatecode", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function payout_update_details_request(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"payoutupdaterequest"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getBinaryTreeData(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/binarytree", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getSingleUserBinaryTreeData(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/singleuserbinarytreedata", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function binaryTeamUsersApi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"teamusers"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function checkhostedpage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/checkhostedpage", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function roidata(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"roidata"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function lasttransactions(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"lastweektransactions"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function referralusers(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"referralusers"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function investmentreport(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"depositsummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function tradingsignalsreport(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"tradingsignalsreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// binary points reports

export function BinaryPointsReport(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"binarypointsreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//  residual reports

export function ResiduelReport(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"residuelreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//  Subscription reports

export function SubscriptionReportApi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"subscriptionreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//  Payout reports

export function PayoutReportDataApi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"payout"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//  Monthly Record reports

export function previousmonthrecordapi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"previuosmonthrecord"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Refferel users reports

export function PersonalReferrals(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"personalreferrals"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getsinglemessage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/getsinglemessage", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getPlansupgrade(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getsubscriptionitemsupgrade", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function UpdateSubscriptionApi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/updatesubscription", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function CheckUpgradeHostedPageApi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/checkupgradehostedpage", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function CheckAffiliateHostedPageApi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/checkaffiliatehostedpage", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function UpdateReferralSide(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/updatereferralside", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function referralbonusreport(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"referralbonussummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function unilevelbonusreport(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"unilevelbonussummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getunilevelreports(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"getunilevelreports"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getpoolreports(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"getpoolreports"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getpooldistributionreports(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"getpooldistributionreports"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getlevelbonusdedcuted(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"getlevelbonusdedcuted"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function transaction(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/transaction", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function alltransaction(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"useralltransactions"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function adminwallet(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"getadminwallet"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getHierarchyData(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"gethierarchy"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfileData(formdata, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"updateprofiledata"}`, formdata)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function cabcelsubscription(formdata, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"cancelsubscription"}`, formdata)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function addupdatewallets(formdata, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"addupdatewallets"}`, formdata)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfilePicture(formdata, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"updateprofilepicture"}`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function uploadKycData(formdata, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"uploadkycdata"}`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfilePassword(
  oldpassword,
  newpassword,
  callback,
  errorCallback
) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"updatepassword"}`, {
      oldpassword,
      newpassword,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function addReseller(
  companyName,
  firstName,
  lastName,
  email,
  phoneNumber,
  companyAddress,
  adminUsername,
  adminPassword,
  callback,
  errorCallback
) {
  updateAuthorizationHeader();
  axios
    .post(`${BASE_URL}${"admin/apis/reseller/add-new"}`, {
      company_name: companyName,
      first_name: firstName,
      last_name: lastName,
      email: email,
      username: adminUsername,
      phone: phoneNumber,
      company_address: companyAddress,
      password: adminPassword,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function editReseller(
  resellerId,
  firstName,
  lastName,
  phoneNumber,
  companyAddress,
  callback,
  errorCallback
) {
  updateAuthorizationHeader();
  axios
    .post(`${BASE_URL}${"/admin/apis/reseller/update-profile"}`, {
      id: resellerId,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      company_address: companyAddress,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateResellerStatus(
  resellerId,
  status,
  callback,
  errorCallback
) {
  updateAuthorizationHeader();
  axios
    .post(`${BASE_URL}${"/admin/apis/reseller/update-profile"}`, {
      id: resellerId,
      status: status,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function requestPasswordReset(email, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"forgetpassword"}`, {
      email,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function investandeearning(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"monthlyinvestandeearning"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function userpayouts(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"userpayouts"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getnotifications(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"getnotifications"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updatenotificationstatus(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"updatenotificationstatus"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getmessageslist(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"getmessageslist"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getwalletlist(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"getwalletlist"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updatemessagestatus(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"updatemessagestatus"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function passwordReset(email, password, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"resetpassword"}`, {
      email,
      password,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function validateEmailToken(token, email, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"validateemailtoken"}`, {
      token,
      email,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function verifyemailaccount(token, email, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"verifyemailaccount"}`, {
      token,
      email,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deletewallet(formdata, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${"deletewallet"}`, formdata)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchGroup(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${EXTENSION_BASE_URL}/${"fetch-group"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchMessage(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${EXTENSION_BASE_URL}/${"segment-message"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//groups

export function fetchGroupList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  const url = "groups/api/all";
  params.prospection_type = params.media === "Instagram" ? "instagram" : null;
  axios
    .get(BASE_URL + url, {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchSocialProfileFeature(params, callback, errorCallback) {
  updateAuthorizationHeader();
  const url =
    params.media === "Instagram"
      ? "api/instagram/single"
      : "api/facebook/single";
  axios
    .get(BASE_URL + url, {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchInstagramGroupList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "groups/instagram/api/all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchKeywordList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  const url =
    params.media === "Instagram"
      ? "keywords/api/all?type=instagram"
      : "keywords/api/all?type=facebook";
  axios
    .get(BASE_URL + url)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchAllKeywordList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "keywords/api/all")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function fetchFolderList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "api/folders/all")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchInstagramKeywordList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "instagram/keywords/api/all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function UpadateUnfollowUser(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + `novadata/api/user-add`,params )
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });

    
}


export function bulkTagUser(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${BASE_URL}` + `api/ext/tag/get-tagged-user`,params )
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });}

export function fetchUnfollowList(params, callback, errorCallback, pagination) {
  updateAuthorizationHeader();
  axios
    .get(
      BASE_URL +
      `novadata/api/all?page=${pagination?.page}&limit=${pagination?.pageSize}&sort=${params?.["sort"]?.toUpperCase()}&field=${params?.field}`,
      {
      
      }
    )
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchLostList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "novadata/api/lost-all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchDeactivated(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "novadata/api/deactivated-all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchWhiteList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "novadata/api/whitelist-all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchUnfreindsList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "novadata/api/unfriend-all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function saveWhitelist(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "novadata/api/save-whitelist", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function removeWhitelistNew(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "novadata/api/remove-whitelist", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function saveunfriended(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "novadata/api/save-unfriended", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function createGroup(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "groups/api/create", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function updateUserGender(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "plan/update-gender", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function createKeyword(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "keywords/api/create", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function createNewKeyword(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "keywords/type/api/create", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function fetchSingleKeyword(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `keywords/api/${params.keywordId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateKeyword(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + `keywords/api/${params.keywordId}`, { ...params.params })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deleteKeyword(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `keywords/api/delete/${params.deleteId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deleteGroup(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `groups/api/delete/${params.deleteId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function deleteGroupInstagram(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `groups/instagram/api/delete/${params.deleteId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// section api

export function fetchSectionList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "section/api/all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function createSection(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "section/api/create", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deleteSection(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `section/api/delete/${params.deleteId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function fetchResellerList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "admin/apis/reseller/get-listing", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deleteReseller(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + `admin/apis/reseller/delete/`, {
      id: params.deleteId,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function duplicateSection(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `section/api/duplicate/${params.duplicateId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function duplicateKeyword(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `keywords/api/duplicate/${params.duplicateId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function singleSection(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `section/api/${params.sectionId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function singleReseller(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + `admin/apis/reseller/get-details`, {
      id: params.resellerId,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateSection(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + `section/api/${params.sectionId}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// messages api

export function fetchMessageList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  const url = "message/api/all";
  params.type =
    params.media === "Instagram" ? "instagram-connect" : params.type;
  axios
    .get(BASE_URL + url, {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchInstagramMessageList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "instagram/message/api/all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function createMessage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "message/api/create", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deleteMessage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `message/api/delete/${params.deleteId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function singleMessage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `message/api/${params.messageId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateMessage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + `message/api/${params.messageId}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function updateInstaFbStage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + `/updateStages`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//create target

export function createTarget(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "target/setting/api/create", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function createSocialTarget(params, callback, errorCallback) {
  updateAuthorizationHeader();
  const url = "target/setting/api/create";
  params.connectData.prospection_type =
    params.media === "Instagram" ? "instagram" : "facebook";
  axios
    .post(BASE_URL + url, params.connectData)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchTargetList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "target/setting/api/all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//create request

export function createRequest(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "request/message/api/create", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function fetchRequestList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "request/message/api/all", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//create birthday

export function createBirthday(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "birthday/setting/api/create", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function fetchBirthdayList(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "birthday/setting/api/fetch", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchCrmGroups(params, callback, errorCallback) {
  updateAuthorizationHeader();
  const url =
    params.media === "Instagram"
      ? "user/api/instagram/group"
      : "user/api/group";
  axios
    .get(BASE_URL + url, {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function fetchInstagramCrmGroups(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "user/api/instagram/group", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchCrmSingleGroups(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `user/api/group/${params.groupId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function fetchInstagramCrmSingleGroups(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `user/api/instagram/group/${params.groupId}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function createCrmGroup(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/group", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function createInstagramCrmGroup(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/instagram/group", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchTaggedUsersapi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "user/api/taggeduser", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function fetchTaggedUsersNotes(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "user/api/note", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function fetchTaggedUsersNotesInsta(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "user/api/note?type=instagram", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchCompaigns(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "user/api/compaigns", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function postRequest(url, params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/${url}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function fetchPlanLimitDetails(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + "plan/plan-details", {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function getStatisticData(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `statistics/api/statistics?type=${params.type}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getStatisticContactData(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `statistics/api/contacts`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getStatisticLimitData(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `statistics/api/limits`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function createMessageTemplate(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + "all/messages/api/create-messages", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateMessageTemplate(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios

  .post(BASE_URL + `all/messages/api/update-messages/${params.message_id}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export const getMyMessageList = (params, callback, errorCallback) => {
  updateAuthorizationHeader();
  axios
    .get(BASE_URL + `all/messages/api/my-message`, {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
};

export const getAllMessagesList = (params, callback, errorCallback) => {
  updateAuthorizationHeader();
  axios
  .post(BASE_URL + `all/messages/api/messages`, {
    visibility_type: params
  })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
};

export const getAllMessageTemplate = (params, callback, errorCallback) => {
  updateAuthorizationHeader();
  axios.get(BASE_URL + `all/messages/api/get-template-messages`, {
    params
  }).then((response) => {
    if (callback) {
      callback(response);
    }
  })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deleteNewMessage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .delete(BASE_URL + `all/messages/api/delete-messages/${params.messageId}`, {
      params: params,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function createUserMessageTemplate(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + `all/messages/api/add-category-to-message`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function setFavoriteMessage(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(BASE_URL + `all/messages/api/set-favorite-message`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getInstagramFollowers(params, callback, errorCallback, pagination) {
  updateAuthorizationHeader();
  axios
    .get(
      BASE_URL +
      `novadata/api/get-insta-followers?category=${params?.category}&privacy=${params?.privacy}&page=${pagination?.page}&limit=${pagination?.pageSize}&sort=${params.sorting?.["sort"]?.toUpperCase()}&field=${params.sorting?.field}`,
      {
      
      }
    )
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getTaggedUsers(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(EXTENSION_BASE_URL + `/taggeduser-api`, {type:"get"})
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
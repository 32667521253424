import React, { useContext, useEffect, useState } from 'react'
import './transformerplan.css'
import logo from "../../../assets/img/logo-plan.svg"
import courseIcon from "../../../assets/img/course-icons.svg"
import courseVid from "../../../assets/img/courseVideoicon.png"
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault'
import { germanEventCharge } from 'backendServices/ApiCalls'
import SweetAlert from '../components/mui/Alerts/SweetAlert'
import { useLocation, useNavigate } from 'react-router-dom'
import { Terms } from '../upgradepriceplan/termData'

const TransformerPlan = () => {
const [loading, setLoading] = useState(false)
const { loginUserData } = useContext(CustomProvider);
 const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refId = queryParams.get('uname') && "SONNY";
  const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: "",
    });
    const navigate = useNavigate()

    const targetDate = new Date("2024-12-31T22:59:00Z"); // UTC equivalent

      function calculateTimeLeft() {
        const now = new Date();
        const difference = targetDate - now;
    
        // Calculate days, hours, minutes, and seconds left
        const timeLeft = {
          days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
            2,
            "0"
          ),
          hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(
            2,
            "0"
          ),
          minutes: String(Math.floor((difference / (1000 * 60)) % 60)).padStart(
            2,
            "0"
          ),
          seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
        };
    
        return difference > 0
          ? timeLeft
          : { days: "00", hours: "00", minutes: "00", seconds: "00" };
      }
    
      // State to keep track of the remaining time
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
      // Update the countdown every second
      useEffect(() => {
    localStorage.setItem("plans", JSON.stringify(Terms));
     localStorage.setItem("referralId", refId)

        const timer = setInterval(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
    
        return () => clearInterval(timer); // Clear timer on component unmount
      }, []);


       const handleBuy = async () => {

        let regionCode = localStorage.getItem("regionCode")
        if (!regionCode) {
            const res = await (await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=2e685b36ba4a4c5cb3645fc6baa56306')).json()
            regionCode = res?.continent_code;
            localStorage.setItem('regionCode', regionCode)
        }

        if (!loginUserData?.customerid) {
            localStorage.setItem("toTransform","True")
            let plan =   regionCode?.toUpperCase() === "EU" ? "Formation-Leads-en-RDV-Qualifies-Basic-Plan-EUR-Monthly" : "Formation-Leads-en-RDV-Qualifies-Basic-Plan-USD-Monthly";
            localStorage.setItem("planId",plan);
            localStorage.setItem("backto","/go")
	        localStorage.setItem("fromOffers", "true");

            navigate("/signup")
            return
        }
              setLoading(true)
             
      //chageBee calling
              const currency = regionCode?.toUpperCase() === "EU" ? "EUR" : "USD";
              const itemPriceId = regionCode?.toUpperCase() === "EU" ? "Formation-Sonny-Transformer-vos-leads-en-RDV-qualifies-EUR" : "Formation-Sonny-Transformer-vos-leads-en-RDV-qualifies-EUR"
              let params = {
                  customerId: loginUserData?.customerid,
                  currency:"EUR",
                  itemPriceId:"Formation-Sonny-Novalya-Transformer-vos-leads-en-RDV-qualifies-EUR"
      
              };
              germanEventCharge(
                  params,
                  (response) => {
                      if (response.data.status === "success") {
      
                          window.location = response?.data?.data?.url
                      } else {
                          setalertData({
                              show: true,
                              message: "Something Went Wrong, Please Try Again",
                              variant: "error",
                          });
                          setLoading(false)
      
                      }
                  },
                  (error) => {
                      setLoading(false)
      
                      setalertData({
                          show: true,
                          message: error?.response?.data?.message,
                          variant: "error",
                      });
                  }
              );
          };

        const  alreadyHaveAccount = ()=>{
            localStorage.setItem("toTransform","True")
            navigate("/login")
        }
  return (
    <>
     {alertData.show && (
                <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
        <div class="course-main">
		<div class="course-header">
			<div class="course-container">
				<div class="course-header-warp">
					<img class="course-head-img" src={logo}/>
				</div>
			</div>
		</div>

		<div class="course-video-wrap">
			<div class="course-container">
        <div className='connect_name'>{loginUserData?.firstname? `You are connected as ${loginUserData?.firstname}`:""}</div>

				<div class="course-videoInner">
					<div class="courserLeft">
						<img class="courseVideoicon" src={courseVid}/>
						<div class="courserVideoWrap">
							<iframe class="course-videos" src="https://player.vimeo.com/video/1039401182?badge=0&autopause=0&player_id=0&app_id=58479" title="Video" allowfullscreen="">
							</iframe>
							
						</div>
					</div>
                   
					<div class="courserRight">
						<span class="course-title">Convertissez Vos Leads en Clients :  <br></br>
              La Méthode Imparable pour Obtenir des Rendez-Vous Qualifiés
            </span>
						<span class="priceOld">Prix Normal : <span>497€</span></span>
						<span class="priceNew">Prix Spécial: <span>100€ </span></span>
						<div class="offerDuration">
							<span>L'offre se termine dans:</span>
							<div id="countdown">
								<div id='tiles'>
                    <span>{timeLeft.days}</span>
                    <span>{timeLeft.hours}</span>
                    <span>{timeLeft.minutes}</span>
                    <span>{timeLeft.seconds}</span>
                  </div>
								<div class="labels">
									<li>Jours</li>
									<li>Heures</li>
									<li>Minutes</li>
									<li>Secondes</li>
								</div>
							</div>
						</div>
						<button class="course-buy-button" onClick={handleBuy}>Achetez Maintenant</button>
                        <div>{!loginUserData?.firstname?<div className='already_account' onClick={()=>alreadyHaveAccount()} >Avez-vous déjà un compte ?</div>:""}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="course-module">
			<div class="module-container">
				<span class="course-title">Programme :</span>
				<div class="module-wraper">
					<div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">Module 1:</span>
							<p>La Psychologie du Lead</p>
						</div>
					</div>
					<div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">Module 2:</span>
							<p>Structurer un Entonnoir de Conversion</p>
						</div>
					</div>
					<div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">Module 3:</span>
							<p>Scripts de Communication et Suivi</p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">Module 4:</span>
							<p>Optimisation et Analyse</p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">Module 5:</span>
							<p>Sécuriser les rendez-vous et réduire les absences</p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">Module 6:</span>
							<p>L’Art de Démarrer un Rendez-Vous Impactant </p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">Bonus #1</span>
							<p>Le replay des 2 jours de Challenge Facebook</p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">Bonus #2*</span>
							<p>3 mois d’abonnement Novalya offerts !</p>
						</div>
					</div>
          <div class="module-items">
						<div class="module-items-icon">
							<img src={courseIcon}/>
						</div>
						<div class="module-item-text">
							<span class="module-title">Bonus #3*</span>
							<p>Accompagnement de démarrage Novalya offert</p>
						</div>
					</div>
          <span class="course-foot">*Pour les Nouveaux Utilisateurs Uniquement</span>

		     <div style={{display:"flex",justifyContent:"center" ,width:"100%", paddingTop:"20px"}}><button class="course-buy-button" onClick={handleBuy}>Achetez Maintenant</button></div>
				</div>
			</div>
		</div>

		<footer class="course-footer">
			<div class="course-container">
				<div class="course-footer-wrap">
					<p class="course-footer-txt">Copyright   |  Novalya  © 2023-2024</p>
				</div>
			</div>
		</footer>
	</div>
    </>
  )
}

export default TransformerPlan
import React, { useEffect } from 'react';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useTheme } from "@mui/material";

const SweetAlert = (props) => {
    const Swal = useSwalWrapper();
    const theme = useTheme();

    useEffect(() => {
        if (props.alertData.show) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                showCloseButton: true, 
                timer: 5000,
                timerProgressBar: true,
                customClass: {
                    popup: 'custom-toast',
                },
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
                willClose: () => {
                    props.setalertData({
                        show: false,
                        message: '',
                        variant: ''
                    });
                }
            });

            const customIcons = {
                success: `<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="26" cy="26" r="26" fill="#ECFDF3"/>
                <circle cx="26" cy="26" r="19" fill="#D1FADF"/>
                <path d="M26 36C27.3134 36.0016 28.6143 35.7437 29.8277 35.241C31.0412 34.7384 32.1434 34.0009 33.071 33.071C34.0009 32.1434 34.7384 31.0412 35.241 29.8277C35.7437 28.6143 36.0016 27.3134 36 26C36.0016 24.6865 35.7437 23.3857 35.241 22.1722C34.7384 20.9588 34.0009 19.8566 33.071 18.929C32.1434 17.9991 31.0412 17.2616 29.8277 16.759C28.6143 16.2563 27.3134 15.9984 26 16C24.6865 15.9984 23.3857 16.2563 22.1722 16.759C20.9588 17.2616 19.8566 17.9991 18.929 18.929C17.9991 19.8566 17.2616 20.9588 16.759 22.1722C16.2563 23.3857 15.9984 24.6865 16 26C15.9984 27.3134 16.2563 28.6143 16.759 29.8277C17.2616 31.0412 17.9991 32.1434 18.929 33.071C19.8566 34.0009 20.9588 34.7384 22.1722 35.241C23.3857 35.7437 24.6865 36.0016 26 36Z" stroke="#08BE6C" stroke-width="2" stroke-linejoin="round"/>
                <path d="M22 26.0001L25 29L31 23.0001" stroke="#08BE6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                `,
                error: `<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="26" cy="26" r="26" fill="#FFF9F5"/>
                <circle cx="26" cy="26" r="19" fill="#FFE7D8"/>
                <path d="M18.9948 34H34.0052C35.5401 34 36.497 32.3307 35.7295 31.0013L28.2243 17.9971C27.4568 16.6676 25.5432 16.6676 24.7757 17.9971L17.2705 31.0013C16.503 32.3307 17.4599 34 18.9948 34ZM26.5 27.0031C25.9518 27.0031 25.5033 26.5533 25.5033 26.0035V24.0044C25.5033 23.4547 25.9518 23.0049 26.5 23.0049C27.0482 23.0049 27.4967 23.4547 27.4967 24.0044V26.0035C27.4967 26.5533 27.0482 27.0031 26.5 27.0031ZM27.4967 31.0013H25.5033V29.0022H27.4967V31.0013Z" fill="#E27140"/>
                </svg>`,
                info: `<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="26" cy="26" r="26" fill="#F4F7FF"/>
                <circle cx="26" cy="26" r="19" fill="#E1E7FF"/>
                <path d="M25.0636 22.6045C24.8732 22.7355 24.7205 22.9142 24.6209 23.1227C24.5364 23.2779 24.395 23.3941 24.2265 23.4471C24.0579 23.5 23.8754 23.4855 23.7174 23.4065C23.5594 23.3276 23.4382 23.1904 23.3793 23.0238C23.3204 22.8573 23.3285 22.6744 23.4018 22.5136C23.5836 22.1482 23.8682 21.7627 24.3082 21.47C24.7518 21.1727 25.3127 21 26 21C26.5982 21 27.2446 21.1773 27.7582 21.5627C28.0445 21.7721 28.2768 22.0467 28.4355 22.3639C28.5943 22.6811 28.675 23.0317 28.6709 23.3864C28.6709 24.3255 28.2046 24.95 27.7373 25.4C27.5646 25.5664 27.3746 25.7255 27.2109 25.8618L27.0773 25.9736C26.9364 26.0876 26.8042 26.2118 26.6818 26.3455V27.5909C26.6818 27.7717 26.61 27.9452 26.4821 28.073C26.3543 28.2009 26.1808 28.2727 26 28.2727C25.8192 28.2727 25.6458 28.2009 25.5179 28.073C25.39 27.9452 25.3182 27.7717 25.3182 27.5909V26.2518C25.3182 26.0364 25.3791 25.7936 25.5427 25.5827C25.7427 25.3282 25.9846 25.1127 26.1955 24.9336L26.3618 24.7945L26.3627 24.7936C26.5191 24.6636 26.6573 24.5482 26.79 24.4191C27.1246 24.0964 27.3073 23.8045 27.3073 23.3864C27.3115 23.2434 27.2802 23.1016 27.2161 22.9737C27.152 22.8458 27.0571 22.7358 26.94 22.6536C26.6964 22.4709 26.3491 22.3636 26 22.3636C25.5509 22.3636 25.26 22.4736 25.0636 22.6045ZM26.9091 30.5455C26.9091 30.7866 26.8133 31.0178 26.6428 31.1883C26.4723 31.3588 26.2411 31.4545 26 31.4545C25.7589 31.4545 25.5277 31.3588 25.3572 31.1883C25.1867 31.0178 25.0909 30.7866 25.0909 30.5455C25.0909 30.3043 25.1867 30.0731 25.3572 29.9026C25.5277 29.7321 25.7589 29.6364 26 29.6364C26.2411 29.6364 26.4723 29.7321 26.6428 29.9026C26.8133 30.0731 26.9091 30.3043 26.9091 30.5455Z" fill="#3C61F2" stroke="#3C61F2" stroke-width="0.5"/>
                <path d="M26 16C31.5227 16 36 20.4773 36 26C36 31.5227 31.5227 36 26 36C20.4773 36 16 31.5227 16 26C16 20.4773 20.4773 16 26 16ZM17.3636 26C17.3636 28.2905 18.2735 30.4872 19.8932 32.1068C21.5128 33.7265 23.7095 34.6364 26 34.6364C28.2905 34.6364 30.4872 33.7265 32.1068 32.1068C33.7265 30.4872 34.6364 28.2905 34.6364 26C34.6364 23.7095 33.7265 21.5128 32.1068 19.8932C30.4872 18.2735 28.2905 17.3636 26 17.3636C23.7095 17.3636 21.5128 18.2735 19.8932 19.8932C18.2735 21.5128 17.3636 23.7095 17.3636 26Z" fill="#3C61F2" stroke="#3C61F2" stroke-width="0.5"/>
                </svg>
                `,
                delete: `<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="26" cy="26" r="26" fill="#FEF3F2"/>
                <circle cx="26" cy="26" r="19" fill="#FEE4E2"/>
                <path d="M30 20V19.2C30 18.0799 30 17.5198 29.782 17.092C29.5903 16.7157 29.2843 16.4097 28.908 16.218C28.4802 16 27.9201 16 26.8 16H25.2C24.0799 16 23.5198 16 23.092 16.218C22.7157 16.4097 22.4097 16.7157 22.218 17.092C22 17.5198 22 18.0799 22 19.2V20M24 25.5V30.5M28 25.5V30.5M17 20H35M33 20V31.2C33 32.8802 33 33.7202 32.673 34.362C32.3854 34.9265 31.9265 35.3854 31.362 35.673C30.7202 36 29.8802 36 28.2 36H23.8C22.1198 36 21.2798 36 20.638 35.673C20.0735 35.3854 19.6146 34.9265 19.327 34.362C19 33.7202 19 32.8802 19 31.2V20" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                `
            };

            Toast.fire({
                iconHtml: customIcons[props.alertData.variant] || '', 
                title: props.alertData.message,
                background: theme.palette.background.paper,
                iconColor: 'transparent',
            });
        }
    }, [props.alertData, Swal, theme, props]);

    return <></>;
};

export default SweetAlert;

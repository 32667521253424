import React, { createContext, useState } from "react";

export const MessageContext = createContext({
    newCreateMessageId: null, 
    setNewCreateMessageId: () => {}, 
});

export const MessageProvider = ({ children }) => {
    const [newCreateMessageId, setNewCreateMessageId] = useState(null);

    return (
        <MessageContext.Provider value={{ newCreateMessageId, setNewCreateMessageId }}>
            {children}
        </MessageContext.Provider>
    );
};

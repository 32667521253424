import { Avatar, Box, Dialog, DialogActions, DialogContent, MenuItem, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react'
import { MessageContext } from '../extension/message-dialog/context/messageContext';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const SendCampaignModalComponent = ({
    sendCampaignModal,
    campiagnModalData,
    setCampiagnModalData,
    messageData,
    changeAttachmentStatus,
    hasAttachment,
    handleCampaignImageChange,
    removeCampaignImage,
    attachmentError,
    groupUsers,
    savedGroups
}) => {
    const [messageId, setMessageId] = useState(null);
    const { newCreateMessageId } = useContext(MessageContext);

    useEffect(() => {
        if (newCreateMessageId !== null && newCreateMessageId !== undefined) {
            setMessageId(newCreateMessageId);
        } else {
            setMessageId(campiagnModalData?.message_id);
        }
    }, [newCreateMessageId, campiagnModalData]);

    return (
        <Dialog
            maxWidth="md"
            open={sendCampaignModal.isOpen}
            sx={{
                ".MuiDialog-container": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
                "& .MuiDialog-paper": {
                    borderRadius: 4,
                },
            }}
        >
            <DialogContent className="camp-main">
                <Box
                    className="campaign-title"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#F4F1FA",
                    }}
                >
                    <Typography
                        mt={1}
                        textAlign="center"
                        component="h5"
                        variant="h5"
                        sx={{
                            fontSize: "20px",
                            textAlign: "center",
                            flexGrow: 1,
                            color: "#2C73FF",
                        }}
                    >
                        {t("pages.title.send_a_campaign")}
                    </Typography>
                </Box>
                <Typography color="grey.600" mt={2} mb={2} variant="body2">
                    {`Send a campaign to ${campiagnModalData?.peopleCount} people`}
                </Typography>
                <Box>
                    <div sx={{ marginBottom: "20px" }}>Select a message</div>
                    <TextField
                        select
                        // label="Message"
                        value={messageId}
                        onChange={(e) => {
                            setMessageId(e.target.value);
                            setCampiagnModalData({
                                ...campiagnModalData,
                                message_id: e.target.value,
                            });
                        }}
                        fullWidth
                        sx={{
                            mt: 1,
                            backgroundColor: "#F6F6F6",
                            mb: 2,
                            width: "500px",
                        }}
                    >
                        {messageData.map((group, index) => (
                            <MenuItem key={group.id} value={group.id}>
                                <span>{group.title}</span>
                            </MenuItem>
                        ))}
                    </TextField>

                    <div sx={{ marginBottom: "20px" }}>
                        Select the time interval{" "}
                    </div>
                    <ToggleButtonGroup
                        className="btn-grooups"
                        value={campiagnModalData?.time_interval}
                        exclusive
                        onChange={(e, newValue) => {
                            if (newValue !== null) {
                                setCampiagnModalData({
                                    ...campiagnModalData,
                                    time_interval: newValue,
                                });
                            }
                        }}
                        sx={{ margin: ".5rem 0", mb: 2 }}
                    >
                        {/* <ToggleButton
                          value="30-60 sec"
                          disabled={campiagnModalData?.peopleCount > 10}
                        >
                          <span>FAST</span> 30-60 sec
                        </ToggleButton> */}
                        <ToggleButton value="1-3 Min" style={{ width: "30%" }}>
                            <span>MEDIUM</span> 1-3 Min
                        </ToggleButton>
                        <ToggleButton value="3-5 Min" style={{ width: "30%" }}>
                            <span>SLOW</span> 3-5 Min
                        </ToggleButton>
                        <ToggleButton value="5-10 Min" style={{ width: "30%" }}>
                            <span>Very Slow</span>
                            5-10 Min
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 1 }}
                >
                    <div style={{ marginBottom: "0px" }}>
                        <label htmlFor="attachment-switch">Do you want to include a file with your message?</label>
                        <Switch
                            checked={hasAttachment}
                            onClick={changeAttachmentStatus}
                            inputProps={{ 'aria-label': 'Attachment switch' }}
                        />
                    </div>

                    {hasAttachment && <>
                        <div style={{ marginBottom: "10px" }}>
                            Supported file types: JPG, PNG, WEBP, GIF
                        </div>
                        <div style={{ display: "flex", gap: "275px", marginBottom: "15px" }}>
                            <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="image-upload"
                                type="file"
                                onChange={handleCampaignImageChange}
                            />

                            <label
                                htmlFor="image-upload"
                                style={{
                                    backgroundColor: "#1c5dcd",
                                    color: "white",
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    borderRadius: "5px",
                                    display: "inline-block",
                                }}
                            >
                                {campiagnModalData.image ? "Change File" : "Upload File"}
                            </label>

                            {campiagnModalData.image &&
                                <Box sx={{ position: "relative" }}>
                                    <Avatar
                                        src={campiagnModalData.image}
                                        alt="Preview"
                                        sx={{ width: 80, height: 80, mt: "-40px" }}
                                    />
                                    <Box component="span" sx={{ position: "absolute", top: "-42px", right: "-20px" }}>
                                        <HighlightOffIcon title="Remove" sx={{ cursor: "pointer" }} onClick={removeCampaignImage} />
                                    </Box>
                                </Box>
                            }
                        </div>
                        {attachmentError && <div style={{ fontSize: "12px", color: "#cd2122", marginTop: "-5px" }}>{attachmentError}</div>}
                    </>}
                </Box>

                <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                >
                    <div sx={{ marginBottom: "20px" }}>
                        Select the next action{" "}
                    </div>

                    <TextField
                        select
                        // label="Action"
                        value={campiagnModalData?.selectAction || false}
                        onChange={(e) => {
                            setCampiagnModalData({
                                ...campiagnModalData,
                                selectAction: e.target.value,
                            });
                            if (e.target.value === "Delete From Group") {
                                setCampiagnModalData({
                                    ...campiagnModalData,
                                    selectAction: e.target.value,
                                    moveGroupId: null,
                                    moveStageId: null,
                                });
                            }
                        }}
                        fullWidth
                        sx={{
                            mt: 1,
                            backgroundColor: "#F6F6F6",
                            mb: 2,
                        }}
                    >
                        <MenuItem value={false}>No Action</MenuItem>
                        <MenuItem value="Move To Stage">Move To Stage</MenuItem>
                        <MenuItem value="Move To Group">Move To Group</MenuItem>
                        <MenuItem value="Delete From Group">
                            Delete From Group
                        </MenuItem>
                    </TextField>
                    {campiagnModalData?.selectAction === "Move To Stage" ? (
                        <TextField
                            select
                            label="Stage"
                            value={campiagnModalData?.moveStageId}
                            onChange={(e) => {
                                setCampiagnModalData({
                                    ...campiagnModalData,
                                    moveStageId: e.target.value,
                                    moveGroupId: groupUsers?.id,
                                });
                            }}
                            fullWidth
                        >
                            {groupUsers?.stage.map((group) => (
                                <MenuItem key={group.stage_num} value={group.id}>
                                    <span>{group.name}</span>
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        ""
                    )}
                    {campiagnModalData?.selectAction === "Move To Group" ? (
                        <>
                            <TextField
                                select
                                label="Group"
                                value={campiagnModalData?.moveGroupId}
                                onChange={(e) => {
                                    setCampiagnModalData({
                                        ...campiagnModalData,
                                        moveGroupId: e.target.value,
                                    });
                                }}
                                fullWidth
                            >
                                {savedGroups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>
                                        <span>{group.name}</span>
                                    </MenuItem>
                                ))}
                            </TextField>
                            {campiagnModalData?.moveGroupId ? (
                                <TextField
                                    select
                                    label="Group Stage"
                                    value={campiagnModalData?.moveStageId}
                                    onChange={(e) => {
                                        setCampiagnModalData({
                                            ...campiagnModalData,
                                            moveStageId: e.target.value,
                                        });
                                    }}
                                    fullWidth
                                >
                                    {savedGroups
                                        .find(
                                            (group) =>
                                                group.id === campiagnModalData?.moveGroupId
                                        )
                                        ?.stage.sort(
                                            (a, b) => a.stage_num - b.stage_num
                                        )
                                        .map((group) => (
                                            <MenuItem
                                                key={group.stage_num}
                                                value={group.id}
                                            >
                                                <span>{group.name}</span>
                                            </MenuItem>
                                        ))}
                                </TextField>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        ""
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: "#F4F1FA" }}>
                <button
                    attr-data={JSON.stringify(campiagnModalData)}
                    id="submit-campaign"
                    className="green-pop-btn"
                >
                    {t("pages.title.send")}
                </button>
                <button
                    onClick={sendCampaignModal.onClose}
                    className="gray-pop-btn"
                >
                    {t("pages.title.cancel")}
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default SendCampaignModalComponent
